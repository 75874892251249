<template lang="pug">
div
  loading-logo(v-if="loading")
  form.d-flex.flex-column(v-else)
    .form-group.row.order-2
      .col-12
        om-input#billing_firstname(
          v-model.trim="billing.firstName"
          type="text"
          :placeholder="billingPlaceholder.firstName"
          :label="$t('firstName') + '*'"
          autocomplete="given-name"
        )
          template(
            slot="error"
            v-if="$v.billing.firstName.$error && !$v.billing.firstName.required"
          )
            span {{ $t('error.field.required') }}
          template(
            slot="error"
            v-if="$v.billing.firstName.$error && !$v.billing.firstName.minLength"
          )
            span {{ $t('error.field.minLength') }}
    .form-group.row(:class="isEn ? 'order-2' : 'order-1'")
      .col-12
        om-input#billing_lastname(
          v-model.trim="billing.lastName"
          type="text"
          :placeholder="billingPlaceholder.lastName"
          :label="$t('lastName') + '*'"
          autocomplete="family-name"
        )
          template(slot="error" v-if="$v.billing.lastName.$error && !$v.billing.lastName.required")
            span {{ $t('error.field.required') }}
          template(
            slot="error"
            v-if="$v.billing.lastName.$error && !$v.billing.lastName.minLength"
          )
            span {{ $t('error.field.minLength') }}
    .form-group.row.order-2
      .col-12
        om-input#billing_name(
          v-model.trim="billing.billingName"
          type="text"
          :placeholder="billingPlaceholder.billingName"
          :label="billing.euVatNumber.length > 0 ? $t('billingName') + '*' : $t('billingName')"
          autocomplete="organization"
        )
          template(
            slot="error"
            v-if="$v.billing.billingName.$error && !$v.billing.billingName.required"
          )
            span {{ $t('error.field.required') }}
    .form-group.row.order-2
      .col-12
        om-input#billing_street(
          v-model.trim="billing.address"
          type="text"
          :placeholder="billingPlaceholder.street"
          :label="$t('address') + '*'"
          autocomplete="street-address"
        )
          template(slot="error" v-if="$v.billing.address.$error && !$v.billing.address.required")
            span {{ $t('error.field.required') }}
          template(slot="error" v-if="$v.billing.address.$error && !$v.billing.address.minLength")
            span {{ $t('error.field.minLength') }}
    .form-group.row.order-2
      .col-12
        om-input#billing_city(
          v-model.trim="billing.city"
          type="text"
          :placeholder="billingPlaceholder.city"
          :label="$t('city') + '*'"
          autocomplete="address-level2"
        )
          template(slot="error" v-if="$v.billing.city.$error && !$v.billing.city.required")
            span {{ $t('error.field.required') }}
    .form-group.row.order-2
      .col-12
        om-input#billing_pcode(
          v-model.trim="billing.postalCode"
          type="text"
          :placeholder="billingPlaceholder.pcode"
          :label="$t('postalCode') + '*'"
          autocomplete="postal-code"
        )
          template(
            slot="error"
            v-if="$v.billing.postalCode.$error && !$v.billing.postalCode.required"
          )
            span {{ $t('error.field.required') }}
          template(
            slot="error"
            v-if="$v.billing.postalCode.$error && !$v.billing.postalCode.minLength"
          )
            span {{ $t('error.field.minLength') }}
    .form-group.row.order-2
      label.col-12.mb-2.font-size-0--75(for="billing_country") {{ $t('country') }}*
      .col-12
        select#billing_country.form-control.form-control-md(
          v-model="billing.countryCode"
          autocomplete="country-name"
        )
          option(v-for="option in getCountries" :value="option.code") {{ option.text }}
    .form-group.row.order-2
      .col-12
        om-input#euVatNumber(
          v-model.trim="billing.euVatNumber"
          type="text"
          :placeholder="billingPlaceholder.euVatNumber"
          :label="!isVatNumberRequired ? $t('euVatNumber') + ' ' + $t('optional') : $t('euVatNumber') + '*'"
        )
          template(slot="error" v-if="$v.billing.euVatNumber.$error")
            span(v-if="!$v.billing.euVatNumber.required") {{ $t('error.field.euVatRequired') }}
    .form-group.row.order-2
      .col-12.mt-3
        om-button(primary type="submit" @click.prevent="saveBilling") {{ $t('save') }}
</template>

<script>
  import { getCountryByLocale } from '@/config/countries';
  import { mapGetters, createNamespacedHelpers } from 'vuex';
  import addressValidation from '@/mixins/address';

  const { mapActions: paymentActions, mapState: paymentState } = createNamespacedHelpers('payment');

  export default {
    name: 'BillingAddress',
    mixins: [addressValidation],
    data() {
      return {
        loading: true,
        countries: [
          { text: 'Hungary', value: 97, code: 'HU' },
          { text: 'United States', value: 223, code: 'US' },
        ],
      };
    },
    computed: {
      ...paymentState(['billing']),
      getCountries() {
        return this.countries;
      },
      getCountryCode() {
        return this.billing.countryCode;
      },
      isEn() {
        return this.getRegion() !== 'Hungary';
      },
      billingPlaceholder() {
        return this.$i18n.locale === 'en'
          ? {
              firstName: 'e. g. John',
              lastName: 'e. g. Appleseed',
              city: 'e. g. New York',
              street: 'e. g. High street 3',
              pcode: 'e. g. 2457',
              euVatNumber: 'e. g. 123456',
              billingName: 'e. g. Best Company',
            }
          : {
              firstName: 'pl. János',
              lastName: 'pl. Kiss',
              city: 'pl. Budapest',
              street: 'pl. Rövid utca 12.',
              pcode: 'pl. 1016',
              euVatNumber: 'pl. 123456',
              billingName: 'pl. Saját Kft.',
            };
      },
    },
    async created() {
      const _self = this;

      _self.$set(_self, 'countries', getCountryByLocale(this.$i18n.locale));
      await this.loadBilling();
      this.loading = false;
    },
    methods: {
      ...paymentActions(['loadBilling']),
      ...mapGetters(['getRegion']),
      saveBilling() {
        this.$v.$touch();

        if (this.$v.$invalid) {
          return;
        }

        const data = { ...this.billing };
        const country = this.getCountryByCode(data.countryCode);

        if (!country) {
          return; // No such country
        }

        data.country = country.value;
        delete data.countryId;
        delete data.nonProfit;

        this.$emit('saveBilling', data);
      },
      getCountryByCode(code) {
        for (const index in this.countries) {
          if (this.countries[index].code === code) {
            return this.countries[index];
          }
        }

        return false;
      },
    },
  };
</script>
