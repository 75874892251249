<template lang="pug">
.container-fluid.px-3
  .row.mb-4
    .col
      om-heading.mt-1(h1) {{ $t('billingAddress') }}
  .row
    .col-5.col-lg-3.mt-1.position-static
      billing-address(@saveBilling="saveBilling")
</template>

<script>
  import UPDATE_BILLING from '@/graphql/UpdateBilling.gql';
  import BillingAddress from '@/components/Payment/BillingAddress';

  export default {
    components: {
      BillingAddress,
    },
    methods: {
      saveBilling(data) {
        this.$apollo
          .mutate({
            mutation: UPDATE_BILLING,
            variables: {
              input: data,
            },
          })
          .then(({ data }) => {
            if (data.updateBilling) {
              this.$notify({
                type: 'success',
                text: this.$t('notifications.saveSuccess'),
              });
            } else {
              this.$notify({
                type: 'error',
                text: this.$t('notifications.saveError'),
              });
            }
          });
      },
    },
  };
</script>
