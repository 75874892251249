import { minLength, required } from 'vuelidate/lib/validators';

export default {
  validations() {
    const validations = {
      billing: {
        firstName: {
          required,
          minLength: minLength(2),
        },
        lastName: {
          required,
          minLength: minLength(2),
        },
        address: {
          required,
          minLength: minLength(5),
        },
        city: {
          required,
          minLength: minLength(3),
        },
        postalCode: {
          required,
          minLength: minLength(3),
        },
        countryCode: {
          required,
          minLength: minLength(2),
        },
        euVatNumber: {},
        billingName: {},
      },
    };

    if (this.isVatNumberRequired) {
      validations.billing.euVatNumber = {
        required,
      };
    }

    if (this.billing.euVatNumber.trim().length > 0) {
      validations.billing.billingName = {
        required,
      };
    }

    return validations;
  },
  computed: {
    isVatNumberRequired() {
      if (this.billing.billingName && this.billing.billingName.trim().length > 0) {
        const country = this.countries.find(({ code }) => code === this.billing.countryCode);

        if (country) {
          // TODO: euVatNumber of Hungary should be TRUE ?
          return country.euVatNumber || country.code === 'HU';
        }
      }

      return false;
    },
  },
};
